<template>
  <div id="app">
    <router-view />
    <audio id="Jaudio" :loop="loop"></audio>
  </div>
</template>
<script>
function bgm(type, src) {
  var audio = document.getElementById("Jaudio");
  audio.setAttribute("src", src);
  if (type == "play") {
    audio.play();
  } else {
    audio.pause();
  }
}
// 这里是AudioContext给安卓用
window.AudioContext =
  window.AudioContext ||
  window.webkitAudioContext ||
  window.mozAudioContext ||
  window.msAudioContext;

var context = new window.AudioContext();
var source = null;
var audioBuffer = null;

function stopSound() {
  if (source) {
    source.stop(0); //立即停止
  }
}

function playSound(type) {
  source = context.createBufferSource();
  source.buffer = audioBuffer;
  if (type == "loop") {
    source.loop = true; //循环播放
  } else {
    source.loop = false;
  }
  source.connect(context.destination);
  source.start(0); //立即播放
}

function initSound(arrayBuffer, type) {
  context.decodeAudioData(
    arrayBuffer,
    function (buffer) {
      //解码成功时的回调函数
      audioBuffer = buffer;
      playSound(type);
    },
    function (e) {
      //解码出错时的回调函数
      console.log("Error decoding file", e);
    }
  );
}

function loadAudioFile(url, type) {
  var xhr = new XMLHttpRequest(); //通过XHR下载音频文件
  xhr.open("GET", url, true);
  xhr.responseType = "arraybuffer";
  xhr.onload = function () {
    //下载完成
    initSound(this.response, type);
  };
  xhr.send();
}
export default {
  data() {
    return {
      page: "https://1317068014.vod2.myqcloud.com/ea1c79e8vodsh1317068014/bd444f9d1397757893463555287/K58GoNZP9dEA.mp3", //背景音乐
      allRight:
        "https://1317068014.vod2.myqcloud.com/ea1c79e8vodsh1317068014/c201e7661397757893463767786/gKqBAfLmYLYA.mp3", //通关
      loop: true,
      isPlay: false,
    };
  },
  methods: {
    play(url, type) {
      // 安卓，加载并播放
      if (!/iphone/.test(navigator.userAgent.toLowerCase())) {
        loadAudioFile(url, type);
      } else {
        bgm("play", url);
      }
    },
    stop(url) {
      if (!/iphone/.test(navigator.userAgent.toLowerCase())) {
        stopSound();
      } else {
        bgm("pause", url);
      }
    },
  },
  mounted() {
    this.$bus.$off("playBJ");
    this.$bus.$on("playBJ", (r) => {
      if (r) {
        if (this.isPlay) return;
        this.isPlay = true;
        this.loop = true;
        this.play(this.page, "loop");
      }
    });
    this.$bus.$off("playTG");
    this.$bus.$on("playTG", (r) => {
      if (r) {
        this.isPlay = false;
        this.stop(this.page);
        this.loop = false;
        this.play(this.allRight, "fail");
      }
    });
  },
  // watch: {
  //   $route: {
  //     handler(to) {
  //       console.log(to);
  //       if (to.path == "/level" || to.path == "/list") {
  //         if (this.isPlay) return;
  //         this.isPlay = true;
  //         this.loop = true;
  //         this.play(this.page, "loop");
  //       }
  //       if (to.path == "/result") {
  //         this.isPlay = false;
  //         this.stop(this.page);
  //         if (to.query && to.query.result == "true") {
  //           this.loop = false;
  //           this.play(this.allRight, "fail");
  //         }
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #2569ab;
  max-width: 750px;
  margin: 0 auto;
}
</style>
