import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'HomeView',
        component: Home
    },
    {
        path: '/user',
        name: 'UserInfo',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/UserInfo.vue')
    },
    {
        path: '/level',
        name: 'LevelView',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Level.vue')
    },
    {
        path: '/list',
        name: 'ListView',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/List.vue')
    },
    {
        path: '/result',
        name: 'ResultView',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Result.vue')
    },
    {
        path: '/rank',
        name: 'RankView',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Rank.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router