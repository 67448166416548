<template>
  <div class="home">
    <img class="top1" src="../assets/headline.png" />
    <img class="top2" src="../assets/subheading.png" />
    <div class="button" @click="next">开始答题</div>
    <img class="bottom1" src="../assets/zhuoyue.png" />
    <img class="bottom2" src="../assets/star.png" />
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
    next() {
      this.$bus.$emit("playBJ", true);
      this.$router.push("/user");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../assets/bg_home.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .button,
  .bottom1,
  .bottom2 {
    position: absolute;
  }

  .top1 {
    width: 90.6%;
    height: auto;
    margin-top: 93.5px;
  }

  .top2 {
    width: 34.6%;
    height: auto;
    margin-top: 48.5px;
  }

  .button {
    width: 39.2%;
    height: 48px;
    background: #00a5f6;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    bottom: 155px;
  }
  .bottom1 {
    width: 50%;
    height: auto;
    bottom: 111.5px;
  }
  .bottom2 {
    width: 44%;
    height: auto;
    bottom: 90px;
  }
}
</style>
